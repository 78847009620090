
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import DashboardCardBarChart from "@/components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "@/components/Cards/DashboardCardPieChart.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import DeviceOverviewSwitchCard from "@/components/Cards/DeviceOverviewSwitchCard.vue";
import DeviceOverviewButtonCard from "@/components/Cards/DeviceOverviewButtonCard.vue";
import DeviceOverviewHeatingBalanceSliderCard from "@/components/Cards/DeviceOverviewHeatingBalanceSliderCard.vue";
import DashboardCardFaultsPieChart from "@/components/Cards/DashboardCardFaultsPieChart.vue";
import DeviceOverviewRoomTargetCard from "@/components/Cards/DeviceOverviewRoomTargetCard.vue";
import { MBDeviceOperationMode } from '@/helpers/data';

@Component({
  components: {
    DashboardCardBarChart,
    DashboardCardPieChart,
    DeviceOverviewCard,
    DeviceOverviewSwitchCard,
    DeviceOverviewButtonCard,
    DeviceOverviewHeatingBalanceSliderCard,
    DeviceOverviewRoomTargetCard,
    DashboardCardFaultsPieChart,
  }
})

export default class MBGeneralView extends Vue {
  @Prop() device: Record<string, any>;

  search = '';
  tableHeaders = [
      {
        text: this.$t('name'),
        align: 'start',
        value: 'name',
      },
      { text: this.$t('value'), value: 'value' },
  ]


  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  getHeatPumpOperationModeText(op: number) {
    return MBDeviceOperationMode(op)["text"];
  }
  getHeatPumpOperationModeIcon(op: number) {
    return MBDeviceOperationMode(op)["icon"];
  }

  getHeatPumpOperationModeColor(op: number) {
    return MBDeviceOperationMode(op)["color"];
  }

  get deviceMetrics() {
    return Object.entries(this.device.data.properties.mb).filter(([key, value]) => !(key === 'time')).map(([key, value]) => {
        return { name: this.$t(key), value: value };
    }) 
  }

};
