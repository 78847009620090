import { render, staticRenderFns } from "./MBChartView.vue?vue&type=template&id=39ee1407&scoped=true&class=fill-height"
import script from "./MBChartView.vue?vue&type=script&lang=ts"
export * from "./MBChartView.vue?vue&type=script&lang=ts"
import style0 from "./MBChartView.vue?vue&type=style&index=0&id=39ee1407&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ee1407",
  null
  
)

export default component.exports