import { render, staticRenderFns } from "./DevicesSideview.vue?vue&type=template&id=384f900f&scoped=true&class=fill-height"
import script from "./DevicesSideview.vue?vue&type=script&lang=ts"
export * from "./DevicesSideview.vue?vue&type=script&lang=ts"
import style0 from "./DevicesSideview.vue?vue&type=style&index=0&id=384f900f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384f900f",
  null
  
)

export default component.exports